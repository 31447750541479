import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {injectGlobal} from 'react-emotion'
import Header from './header'
import Footer from './footer'
import './layout.css'
import 'normalize.css'
import {device} from '../styles/media-queries'

injectGlobal`
  font-size: 1.25rem;
  body {
    @media ${device.tablet} {
      font-size: 1.25rem;
    }
  }
  p {
    line-height: 1.3;
  }
`

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'SCIT makes systems secure through moving target defense',
            },
            {
              name: 'keywords',
              content:
                'scit, moving target defense, cybersecurity, secure systems',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
