import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'react-emotion'
import { device } from '../styles/media-queries'

const BackgroundImageContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      height: 100%;
      overflow: hidden;
    }
  }
`

const BgImageCompnent = ({ image }) => (
  <BackgroundImageContainer>
    <Img fluid={image} />
  </BackgroundImageContainer>
)

export default BgImageCompnent

BgImageCompnent.propTypes = {
  image: PropTypes.node.isRequired,
}
