import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import BgImage from './bgImage'
import {device} from '../styles/media-queries'

const PageHeader = styled.div`
  padding: 15.25% 0;
  color: #fff;
  text-align: center;
  position: relative;
`
const PageTitle = styled.h1`
  margin: 0;
  @media ${device.laptop} {
    font-size: 3.5rem;
  }
`
const PageHeaderComponent = ({children, image}) => (
  <PageHeader>
    <PageTitle>{children}</PageTitle>
    <BgImage image={image.fluid} />
  </PageHeader>
)

export default PageHeaderComponent

PageHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
}
