import React from 'react'
import styled from 'react-emotion'
import {outerPadding} from '../styles/vars'
import SCITLogo from '../images/svg/scit-logo-new.svg'
import AWSLogo from '../images/aws-partner-logo.png'
import {device} from '../styles/media-queries'

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  > * {
    margin-bottom: 0.35rem;
    line-height: 1.1;
  }
  @media ${device.tablet} {
    padding: ${outerPadding};
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`

const LogoWrap = styled.div`
  text-align: center;
  max-width: 300px;
  svg {
    width: 45%;
    @media ${device.laptop} {
    }
    margin: 0 auto;
    display: inline-block;
  }
`

const AWSLogoWrap = styled.div`
  max-width: 200px;
  margin: 2rem 0 1rem;
`
const CopyRight = styled.strong``

const FooterComponent = props => (
  <Footer {...props}>
    <LogoWrap>
      <SCITLogo stroke="#000" />
    </LogoWrap>
    <AWSLogoWrap>
      <img src={AWSLogo} alt="aws logo" />
    </AWSLogoWrap>
    <div>
      {/* <Link href="mailto:asood@scitlabs.com">asood@scitlabs.com</Link> */}
    </div>
    <CopyRight>© 2018 - {new Date().getFullYear()} SCIT Labs Inc.</CopyRight>
  </Footer>
)

export default FooterComponent
