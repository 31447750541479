import React from 'react'
import styled from 'react-emotion'
import Nav from './nav'

const Header = styled.header``

const HeaderComponent = () => (
  <Header>
    <Nav />
  </Header>
)

export default HeaderComponent
