import styled from 'react-emotion'
import {device} from '../styles/media-queries'

export default styled.div`
  margin: 0 auto;
  padding: 2rem 1rem 1rem 1rem;
  &:not(:last-of-type) {
    padding-bottom: 0;
  }
  @media ${device.laptop} {
    max-width: 768px;
    padding: 3rem;
  }
`
