import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'react-emotion'
import { black, blue } from '../styles/colors'

const baseLinkStyles = `
  text-decoration: none;
  color: ${black};
  transition: color 200ms ease;
  position: relative;
  display: inline-block;
  :hover,
  &.active {
    color: ${blue};
    text-decoration: none;
    :after {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.active {
    cursor: default;
  }
  :after {
    content: '';
    background-color: ${blue};
    height: 3px;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: scale(0);
    transition: opacity .125s cubic-bezier(.645,.045,.355,1),transform .125s cubic-bezier(.645,.045,.355,1),-webkit-transform .125s cubic-bezier(.645,.045,.355,1);
  }

`

const Link = styled(GatsbyLink)`
  ${baseLinkStyles}
  &.logo-link:after {
    content: none;
  }
`

const A = styled.a`
  ${baseLinkStyles}
`

const ScitLink = props => {
  return props.href ? (
    <A {...props} />
  ) : (
    <Link activeClassName="active" {...props} />
  )
}

export default ScitLink

ScitLink.propTypes = {
  href: PropTypes.string,
}
